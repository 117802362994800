<template>
  <div :id="mapID" class="map-container"></div>
</template>

<script>
import loadScript from "../../utils/loadScript";
import { markRaw } from "vue";
import {
  gcj02towgs84,
  wgs84togcj02,
  gcj02tobd09,
  bd09togcj02,
} from "../../utils/gps";
export default {
  props: {
    mapID: {
      type: String,
      default: "map-container",
    },
    ayHistory: {
      type: Array,
      default: () => [],
    },
    ayData: {
      type: Array,
      default: () => [],
    },
    ayMarker: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      lnglat: { lat: -34.397, lng: 150.644 },
      ayObj: [],
      currObj: null,
      drawingManager: null,
    };
  },
  mounted() {
    window.initMap = this.initMap;
    this.loadScripts();
  },
  methods: {
    toMap(lng, lat) {
      let ay = gcj02tobd09(lng, lat);
      return new BMap.Point(parseFloat(ay[0]), parseFloat(ay[1]));
    },
    toData(lng, lat) {
      let ay = bd09togcj02(lng, lat);
      // ay = gcj02tobd09(ay[0], ay[1]);
      return { lng: ay[0], lat: ay[1] };
    },

    clearDraw() {
      if (this.currObj) {
        // this.map.clearOverlays()
        this.map.removeOverlay(this.currObj);
      }
    },
    /*
    假设谷歌地图的矩形坐标为:

southwest = {lat: 39.915, lng: 116.380}
northeast = {lat: 39.935, lng: 116.420}
那么可以转换成百度地图的坐标如下:

var point1 = new BMap.Point(116.380, 39.935); // 左下角
var point2 = new BMap.Point(116.420, 39.935); // 右下角
var point3 = new BMap.Point(116.420, 39.915); // 右上角
var point4 = new BMap.Point(116.380, 39.915); // 左上角
    */
    showModel(ay) {
      this.map.clearOverlays();
      this.ayObj.splice(0);
      //0:矩形 1：多边形 2：圆形 3：线路 4：兴趣点
      let pt_str = "";
      let pt, pt2;
      let ptObj, ptObj2;
      let pt_ay;
      let ay_pos = [];
      console.log("aa", ay);
      ay.forEach((it) => {
        ay_pos = [];
        pt_str = it.rRectPoints;
        switch (parseInt(it.rRectType)) {
          case 0:
            pt_ay = pt_str.split(";");
            // pt = pt_ay[0].split(",");
            // ptObj = this.toMap(parseFloat(pt[0]), parseFloat(pt[1]));
            // pt2 = pt_ay[1].split(",");
            // ptObj2 = this.toMap(parseFloat(pt2[0]), parseFloat(pt2[1]));
            pt_ay.forEach((it) => {
              pt = it.split(",");
              ay_pos.push(this.toMap(parseFloat(pt[0]), parseFloat(pt[1])));
            });
            this.showDraw(0, { ay_pos: ay_pos });
            // this.showDraw(0, {
            //   southwest: ptObj2,
            //   northeast: ptObj,

            //   ay_pos: [
            //     this.toMap(parseFloat(pt2[0]), parseFloat(pt[1])), //左上角
            //     this.toMap(parseFloat(pt[0]), parseFloat(pt[1])), //右上角
            //     this.toMap(parseFloat(pt[0]), parseFloat(pt2[1])), //右下角
            //     this.toMap(parseFloat(pt2[0]), parseFloat(pt2[1])), //左下角
            //   ],
            // });
            this.map.panTo(ay_pos[0]);
            break;
          case 1:
            pt_ay = pt_str.split(";");
            pt_ay.forEach((it) => {
              pt = it.split(",");
              ay_pos.push(this.toMap(parseFloat(pt[0]), parseFloat(pt[1])));
            });
            this.showDraw(1, { ay_pos: ay_pos });
            this.map.panTo(ay_pos[0]);
            break;
          case 2:
            pt_ay = pt_str.split("|");
            pt = pt_ay[0].split(",");
            ptObj = this.toMap(parseFloat(pt[0]), parseFloat(pt[1]));
            this.showDraw(2, {
              center: ptObj,
              population: parseFloat(pt_ay[1]),
            });
            this.map.panTo(ptObj);
            break;
          case 3:
            pt_ay = pt_str.split(";");
            pt_ay.forEach((it) => {
              pt = it.split(",");
              ay_pos.push(this.toMap(parseFloat(pt[0]), parseFloat(pt[1])));
            });
            this.showDraw(3, { ay_pos: ay_pos });
            this.map.panTo(ay_pos[0]);
            break;
          case 4:
            pt_ay = pt_str.split(",");
            ptObj = this.toMap(parseFloat(pt_ay[0]), parseFloat(pt_ay[1]));
            this.showDraw(4, { ptObj: ptObj });
            this.map.panTo(ptObj);
            break;
        }
      });
    },
    showDraw(drawType, obj) {
      // 0:矩形 1：多边形 2：圆形 3：线路 4：兴趣点
      const color = { strokeColor: "000000", fillColor: "FF0000" };
      switch (drawType) {
        case 0:
          // 定义矩形的边界点
          // var southwest = new BMap.Point(116.38, 39.915); // 西南角点
          // var northeast = new BMap.Point(116.42, 39.935); // 东北角点
          const rectangle = new BMap.Polygon(obj["ay_pos"], {
            strokeColor: "red",
            strokeWeight: 4,
            strokeOpacity: 1,
          }); //创建多边形
          this.map.addOverlay(rectangle); //增加矩形
          this.ayObj.push(rectangle);
          break;
        case 1:
          // const triangleCoords = [
          //   { lat: 25.774, lng: -80.19 },
          //   { lat: 18.466, lng: -66.118 },
          //   { lat: 32.321, lng: -64.757 },
          //   { lat: 25.774, lng: -80.19 },
          // ];
          // Construct the polygon.
          const bermudaTriangle = new BMap.Polygon(obj["ay_pos"], {
            strokeColor: "red",
            strokeWeight: 4,
            strokeOpacity: 1,
          }); //创建多边形
          this.map.addOverlay(bermudaTriangle); //增加多边形
          this.ayObj.push(bermudaTriangle);
          break;
        case 2:
          const cityCircle = new BMap.Circle(obj.center, obj.population, {
            strokeColor: "red",
            strokeWeight: 4,
            strokeOpacity: 1,
          }); //创建圆形
          this.map.addOverlay(cityCircle);
          this.ayObj.push(cityCircle);
          break;
        case 3:
          // const flightPlanCoordinates = [
          //   { lat: 37.772, lng: -122.214 },
          //   { lat: 21.291, lng: -157.821 },
          //   { lat: -18.142, lng: 178.431 },
          //   { lat: -27.467, lng: 153.027 },
          // ];
          const flightPath = new BMap.Polyline(obj.ay_pos, {
            strokeColor: "red",
            strokeWeight: 4,
            strokeOpacity: 1,
          }); //创建折线
          this.map.addOverlay(flightPath);
          this.ayObj.push(flightPath);
          break;
        case 4:
          const marker = new BMap.Marker(obj.ptObj); // 创建标注
          this.map.addOverlay(marker);
          this.ayObj.push(marker);
          break;
      }
    },
    drawModel(drawType) {
      //清空所有画图
      this.map.clearOverlays();
      this.ayObj.splice(0);

      switch (parseInt(drawType)) {
        case 1:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_MARKER);
          break;
        case 2:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_CIRCLE);
          break;
        case 3:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYGON);
          break;
        case 4:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_POLYLINE);
          break;
        case 5:
          this.drawingManager.setDrawingMode(BMAP_DRAWING_RECTANGLE);
          break;
        case 6:
          this.drawingManager.close();
          break;
      }
      this.drawingManager.open();
    },
    initDraw() {
      //////////////////////////////////////////////
      let styleOptions = {
        strokeColor: "red", //边线颜色。
        fillColor: "red", //填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 3, //边线的宽度，以像素为单位。
        strokeOpacity: 0.8, //边线透明度，取值范围0 - 1。
        fillOpacity: 0.6, //填充的透明度，取值范围0 - 1。
        strokeStyle: "solid", //边线的样式，solid或dashed。
      };
      //实例化鼠标绘制工具
      this.drawingManager = new window.BMapLib.DrawingManager(this.map, {
        isOpen: false, //是否开启绘制模式
        enableDrawingTool: false, //是否显示工具栏
        drawingToolOptions: {
          anchor: BMAP_ANCHOR_TOP_LEFT, //位置
          offset: new BMap.Size(5, 5), //偏离值
        },
        circleOptions: styleOptions, //圆的样式
        polylineOptions: styleOptions, //线的样式
        polygonOptions: styleOptions, //多边形的样式
        rectangleOptions: styleOptions, //矩形的样式
      });
      //添加鼠标绘制工具监听事件，用于获取绘制结果
      this.drawingManager.addEventListener("overlaycomplete", (evt) => {
        this.currObj = evt.overlay;
        console.log(evt.overlay);
        // overlays.push(evt);
        let radius;
        let output = {};
        let ay_lnglat = [];
        let pt;
        switch (evt.drawingMode) {
          case "marker":
            pt = this.toData(
              evt.overlay.getPosition().lng,
              evt.overlay.getPosition().lat
            );
            output = {
              type: "MARKER",
              lng: pt.lng,
              lat: pt.lat,
              // lng: event.overlay.position.lng(),
              // lat: event.overlay.position.lat(),
            };
            break;
          case "circle":
            pt = this.toData(
              evt.overlay.getCenter().lng,
              evt.overlay.getCenter().lat
            );
            output = {
              type: "CIRCLE",
              radius: evt.overlay.getRadius(),
              lng: pt.lng,
              lat: pt.lat,
              // lng: event.overlay.center.lng(),
              // lat: event.overlay.center.lat(),
            };
            break;
          case "polyline":
            for (var i = 0; i < evt.overlay.getPath().length; i++) {
              pt = this.toData(
                evt.overlay.getPath()[i].lng,
                evt.overlay.getPath()[i].lat
              );
              ay_lnglat.push({
                lng: pt.lng,
                lat: pt.lat,
              });
            }

            output = {
              type: "POLYGON",
              ay_lnglat: ay_lnglat,
            };
            break;
          case "polygon":
            for (var i = 0; i < evt.overlay.getPath().length; i++) {
              pt = this.toData(
                evt.overlay.getPath()[i].lng,
                evt.overlay.getPath()[i].lat
              );
              ay_lnglat.push({
                lng: pt.lng,
                lat: pt.lat,
                // lng: evt.overlay.getPath().getArray()[i].lng(),
                // lat: evt.overlay.getPath().getArray()[i].lat(),
              });
            }

            output = {
              type: "POLYGON",
              ay_lnglat: ay_lnglat,
            };
            break;
          case "rectangle":
            for (var i = 0; i < evt.overlay.getPath().length; i++) {
              pt = this.toData(
                evt.overlay.getPath()[i].lng,
                evt.overlay.getPath()[i].lat
              );
              ay_lnglat.push({
                lng: pt.lng,
                lat: pt.lat,
                // lng: evt.overlay.getPath().getArray()[i].lng(),
                // lat: evt.overlay.getPath().getArray()[i].lat(),
              });
            }

            output = {
              type: "RECTANGLE",
              ay_lnglat: ay_lnglat,
            };

            break;
        }
        this.drawingManager.close();
        this.clickEvent(output);
      });
    },
    loadScripts() {
      let baiduMapEle = document.getElementById("baiduMapEle");
      if (!baiduMapEle) {
        let link = document.createElement("link");
        link.href =
          "https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.css";
        link.rel = "stylesheet";
        link.type = "text/css";
        document.getElementsByTagName("head")[0].appendChild(link);
        loadScript(
          "baiduMapEle",
          "https://api.map.baidu.com/api?v=3.0&ak=GFD3HXy0CtiNpEGK0CxTrbGOXtIy0WdU&callback=initMap"
        );
      } else {
        this.initMap();
      }
    },
    async initMap() {
      let baiduMapDrawEle = document.getElementById("baiduMapDrawEle");
      if (!baiduMapDrawEle) {
        await loadScript(
          "baiduMapDrawEle",
          "https://api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js"
        );
        await loadScript(
          "baiduTextIconOverlayEle",
          "https://api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js"
        );
        await loadScript(
          "baiduMarkerClustererEle",
          "https://api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js"
        );
      }

      this.map = new BMap.Map(this.mapID); // 创建Map实例"map-container"
      this.lnglat = new BMap.Point(116.404, 39.915); // 创建点坐标
      this.map.centerAndZoom(this.lnglat, 13);
      this.map.enableScrollWheelZoom(); // 启用滚轮放大
      //根据浏览定位
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(
      //     (position) => {
      //       this.lnglat = {
      //         lat: position.coords.latitude,
      //         lng: position.coords.longitude,
      //       };
      //       this.map.panTo(this.lnglat);
      //       // this.map.setCenter(this.lnglat);
      //     },
      //     () => {
      //       console.log("location error");
      //       // handleLocationError(true, infoWindow, map.getCenter());
      //     }
      //   );
      // }

      this.initDraw();
      //加载地图数据
      this.initData();
    },
    initData() {
      // console.log("google", this.ayMarker);
      // this.showDraw(0);
      // this.showDraw(1);
      // this.showDraw(2);
      // this.showDraw(3);
      // this.showDraw(4);
      // this.showDraw(5);
    },
    clickEvent(obj) {
      this.$emit("DRAWEVENT", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 100%;
}
</style>
