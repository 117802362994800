<template>
  <div :id="mapID" class="map-container"></div>
</template>

<script>
import loadScript from "../../utils/loadScript";
import { markRaw, toRaw } from "vue";
import { gcj02towgs84, wgs84togcj02, gcj02tobd09 } from "../../utils/gps";
export default {
  props: {
    mapID: {
      type: String,
      default: "map-container",
    },
    ayHistory: {
      type: Array,
      default: () => [],
    },
    ayData: {
      type: Array,
      default: () => [],
    },
    ayMarker: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      lnglat: { lat: -34.397, lng: 150.644 },
      ayObj: [],
      currObj: null,
    };
  },
  mounted() {
    window.initMap = this.initMap;
    this.loadScripts();
  },
  methods: {
    toMap(lng, lat) {
      let ay = gcj02towgs84(lng, lat);
      return { lng: ay[0], lat: ay[1] };
    },
    toData(lng, lat) {
      let ay = wgs84togcj02(lng, lat);
      // ay = gcj02tobd09(ay[0], ay[1]);
      return { lng: ay[0], lat: ay[1] };
    },
    clearDraw() {
      if (this.currObj) {
        console.log(this.currObj);
        toRaw(this.currObj).setMap(null);
      }
    },
    showModel(ay) {
      this.ayObj.forEach((it) => {
        it.setMap(null);
      });
      this.ayObj.splice(0);
      //0:矩形 1：多边形 2：圆形 3：线路 4：兴趣点
      let pt_str = "";
      let pt, pt2;
      let ptObj, ptObj2;
      let pt_ay;
      let ay_pos = [];

      ay.forEach((it) => {
        ay_pos = [];
        pt_str = it.rRectPoints;
        switch (parseInt(it.rRectType)) {
          case 0:
            pt_ay = pt_str.split(";");
            pt = pt_ay[0].split(",");
            ptObj = this.toMap(parseFloat(pt[0]), parseFloat(pt[1])); //southwest
            pt2 = pt_ay[2].split(",");
            ptObj2 = this.toMap(parseFloat(pt2[0]), parseFloat(pt2[1])); //northeast

            this.showDraw(0, {
              north: ptObj2.lat,
              south: ptObj.lat,
              east: ptObj2.lng,
              west: ptObj.lng,
            });
            this.map.panTo(ptObj);
            break;
          case 1:
            pt_ay = pt_str.split(";");
            pt_ay.forEach((it) => {
              pt = it.split(",");
              ay_pos.push(this.toMap(parseFloat(pt[0]), parseFloat(pt[1])));
            });
            this.showDraw(1, { ay_pos: ay_pos });
            this.map.panTo(ay_pos[0]);
            break;
          case 2:
            pt_ay = pt_str.split("|");
            pt = pt_ay[0].split(",");
            ptObj = this.toMap(parseFloat(pt[0]), parseFloat(pt[1]));
            this.showDraw(2, {
              center: ptObj,
              population: parseFloat(pt_ay[1]),
            });
            this.map.panTo(ptObj);
            break;
          case 3:
            pt_ay = pt_str.split(";");
            pt_ay.forEach((it) => {
              pt = it.split(",");
              ay_pos.push(this.toMap(parseFloat(pt[0]), parseFloat(pt[1])));
            });
            this.showDraw(3, { ay_pos: ay_pos });
            this.map.panTo(ay_pos[0]);
            break;
          case 4:
            pt_ay = pt_str.split(",");
            ptObj = this.toMap(parseFloat(pt_ay[0]), parseFloat(pt_ay[1]));
            this.showDraw(4, { ptObj: ptObj });
            this.map.panTo(ptObj);
            break;
        }
      });
    },
    showDraw(drawType, obj) {
      // 0:矩形 1：多边形 2：圆形 3：线路 4：兴趣点
      const color = { strokeColor: "#ff0000", fillColor: "#FF0000" };
      switch (drawType) {
        case 0:
          const rectangle = new google.maps.Rectangle({
            strokeColor: color.strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color.fillColor,
            fillOpacity: 0.45,
            // editable: true,
            bounds: obj,
          });
          google.maps.event.addListener(
            rectangle,
            "bounds_changed",
            function () {
              console.log("Rectangle changed.", rectangle);
            }
          );
          rectangle.setMap(this.map);
          this.ayObj.push(rectangle);
          break;
        case 1:
          // const triangleCoords = [
          //   { lat: 25.774, lng: -80.19 },
          //   { lat: 18.466, lng: -66.118 },
          //   { lat: 32.321, lng: -64.757 },
          //   { lat: 25.774, lng: -80.19 },
          // ];
          // Construct the polygon.
          const bermudaTriangle = new google.maps.Polygon({
            paths: obj["ay_pos"],
            strokeColor: color.strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color.fillColor,
            fillOpacity: 0.35,
            // editable: true,
            // draggable: true,
          });
          google.maps.event.addListener(
            bermudaTriangle.getPath(),
            "set_at",
            function (event) {
              console.log(
                "Polygon changed.",
                bermudaTriangle.getPath().getArray()
              );
            }
          );
          bermudaTriangle.setMap(this.map);
          this.ayObj.push(bermudaTriangle);
          break;
        case 2:
          // const vancouver = {
          //   center: { lat: 49.25, lng: -123.1 },
          //   population: 603502,
          // };
          const cityCircle = new google.maps.Circle({
            strokeColor: color.strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color.fillColor,
            fillOpacity: 0.35,
            center: obj.center,
            // editable: true,
            radius: obj.population,
          });
          google.maps.event.addListener(
            cityCircle,
            "radius_changed",
            function (event) {
              console.log("Circle changed.", cityCircle);
            }
          );
          cityCircle.setMap(this.map);
          this.ayObj.push(cityCircle);
          break;
        case 3:
          // const flightPlanCoordinates = [
          //   { lat: 37.772, lng: -122.214 },
          //   { lat: 21.291, lng: -157.821 },
          //   { lat: -18.142, lng: 178.431 },
          //   { lat: -27.467, lng: 153.027 },
          // ];
          const flightPath = new google.maps.Polyline({
            path: obj.ay_pos,
            geodesic: true,
            strokeColor: color.strokeColor,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            // editable: true,
          });
          google.maps.event.addListener(
            flightPath.getPath(),
            "set_at",
            function (event) {
              console.log("Polyline changed.", flightPath);
            }
          );

          flightPath.setMap(this.map);
          this.ayObj.push(flightPath);
          break;
        case 4:
          // const myLatLng = { lat: -25.363, lng: 131.044 };
          const marker = new google.maps.Marker({
            position: obj.ptObj,
            // title: "Hello World!",
          });
          marker.setMap(this.map);
          this.ayObj.push(marker);
          break;
      }
    },
    drawModel(drawType) {
      //清空所有画图
      if (this.ayObj.length > 0) {
        this.ayObj.forEach((it) => {
          it.setMap(null);
        });
        this.ayObj.splice(0);
      }
      switch (drawType) {
        case 1:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.MARKER
          );
          break;
        case 2:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.CIRCLE
          );
          break;
        case 3:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.POLYGON
          );
          break;
        case 4:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.POLYLINE
          );
          break;
        case 5:
          this.drawingManager.setDrawingMode(
            google.maps.drawing.OverlayType.RECTANGLE
          );
          break;
        case 6:
          this.drawingManager.setDrawingMode(null);
          break;
      }
    },
    initDraw() {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        // drawingMode: google.maps.drawing.OverlayType.MARKER,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {
          icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },
        circleOptions: {
          strokeColor: "#ff0000",
          strokeOpacity: 0.8,
          fillColor: "#ff0000",
          fillOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
        polygonOptions: {
          strokeColor: "#ff0000",
          strokeOpacity: 0.8,
          fillColor: "#ff0000",
          fillOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
        rectangleOptions: {
          strokeColor: "#ff0000",
          strokeOpacity: 0.8,
          fillColor: "#ff0000",
          fillOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
        polylineOptions: {
          strokeColor: "#ff0000",
          strokeOpacity: 0.8,
          fillColor: "#ff0000",
          fillOpacity: 0.5,
          strokeWeight: 5,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });

      google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        (event) => {
          this.currObj = event.overlay;
          console.log("overlaycomplete", event);
          let radius;
          let output = {};
          let ay_lnglat = [];
          let pt;
          switch (event.type) {
            case google.maps.drawing.OverlayType.MARKER:
              pt = this.toData(
                event.overlay.position.lng(),
                event.overlay.position.lat()
              );
              output = {
                type: "MARKER",
                lng: pt.lng,
                lat: pt.lat,
                // lng: event.overlay.position.lng(),
                // lat: event.overlay.position.lat(),
              };

              break;
            case google.maps.drawing.OverlayType.CIRCLE:
              pt = this.toData(
                event.overlay.center.lng(),
                event.overlay.center.lat()
              );
              output = {
                type: "CIRCLE",
                radius: event.overlay.radius,
                lng: pt.lng,
                lat: pt.lat,
                // lng: event.overlay.center.lng(),
                // lat: event.overlay.center.lat(),
              };
              break;
            case google.maps.drawing.OverlayType.POLYGON:
              // console.log("POLYGON", event.overlay.getPath().getArray());
              for (
                var i = 0;
                i < event.overlay.getPath().getArray().length;
                i++
              ) {
                pt = this.toData(
                  event.overlay.getPath().getArray()[i].lng(),
                  event.overlay.getPath().getArray()[i].lat()
                );
                ay_lnglat.push({
                  lng: pt.lng,
                  lat: pt.lat,
                  // lng: event.overlay.getPath().getArray()[i].lng(),
                  // lat: event.overlay.getPath().getArray()[i].lat(),
                });
              }

              output = {
                type: "POLYGON",
                ay_lnglat: ay_lnglat,
              };

              break;
            case google.maps.drawing.OverlayType.POLYLINE:
              // console.log("POLYLINE", event.overlay.getPath().getArray());
              for (
                var i = 0;
                i < event.overlay.getPath().getArray().length;
                i++
              ) {
                pt = this.toData(
                  event.overlay.getPath().getArray()[i].lng(),
                  event.overlay.getPath().getArray()[i].lat()
                );
                ay_lnglat.push({
                  lng: pt.lng,
                  lat: pt.lat,
                  // lng: event.overlay.getPath().getArray()[i].lng(),
                  // lat: event.overlay.getPath().getArray()[i].lat(),
                });
              }

              output = {
                type: "POLYLINE",
                ay_lnglat: ay_lnglat,
              };
              break;
            case google.maps.drawing.OverlayType.RECTANGLE:
              // pt = this.toData(
              //   event.overlay.getBounds().getNorthEast().lng(),
              //   event.overlay.getBounds().getNorthEast().lat()
              // );
              // ay_lnglat.push({
              //   lng: pt.lng,
              //   lat: pt.lat,
              //   // lng: event.overlay.getBounds().getNorthEast().lng(),
              //   // lat: event.overlay.getBounds().getNorthEast().lat(),
              // });
              // pt = this.toData(
              //   event.overlay.getBounds().getSouthWest().lng(),
              //   event.overlay.getBounds().getSouthWest().lat()
              // );
              // ay_lnglat.push({
              //   lng: pt.lng,
              //   lat: pt.lat,
              //   // lng: event.overlay.getBounds().getSouthWest().lng(),
              //   // lat: event.overlay.getBounds().getSouthWest().lat(),
              // });
              ay_lnglat = [
                this.toData(
                  event.overlay.getBounds().getSouthWest().lng(),
                  event.overlay.getBounds().getSouthWest().lat()
                ), //左上角
                this.toData(
                  event.overlay.getBounds().getNorthEast().lng(),
                  event.overlay.getBounds().getSouthWest().lat()
                ), //右上角
                this.toData(
                  event.overlay.getBounds().getNorthEast().lng(),
                  event.overlay.getBounds().getNorthEast().lat()
                ), //右下角
                this.toData(
                  event.overlay.getBounds().getSouthWest().lng(),
                  event.overlay.getBounds().getNorthEast().lat()
                ), //左下角
              ];

              output = {
                type: "RECTANGLE",
                ay_lnglat: ay_lnglat,
              };

              break;
          }
          this.drawingManager.setDrawingMode(null);
          this.clickEvent(output);
        }
      );
      this.drawingManager.setMap(this.map);
    },
    loadScripts() {
      let ele = document.getElementById("googleMapEle");
      if (!ele) {
        loadScript(
          "googleMapEle",
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyDUXiSEA0nXGCQhWjoWOfESxb5CmRiaVu0&libraries=drawing&callback=initMap"
        );
      } else {
        this.initMap();
      }
    },
    async initMap() {
      this.map = markRaw(
        new google.maps.Map(document.getElementById(this.mapID), {
          center: this.lnglat,
          zoom: 13,
        })
      );
      // 监听地图上的点击事件
      google.maps.event.addListener(this.map, "click", function (event) {
        console.log(event);
        // 判断用户点击的位置是否在标记上
        // if (marker.getBounds().contains(event.latLng)) {
        //   // 如果用户点击了标记，执行相应的操作
        //   console.log("用户点击了标记");
        // }
      });
      //根据浏览定位
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(
      //     (position) => {
      //       this.lnglat = {
      //         lat: position.coords.latitude,
      //         lng: position.coords.longitude,
      //       };
      //       this.map.panTo(this.lnglat);
      //       // this.map.setCenter(this.lnglat);
      //     },
      //     () => {
      //       console.log("location error");
      //       // handleLocationError(true, infoWindow, map.getCenter());
      //     }
      //   );
      // }

      this.initDraw();
      //加载地图数据
      this.initData();
    },
    initData() {
      // console.log("google", this.ayMarker);
      // this.showDraw(0);
      // this.showDraw(1);
      // this.showDraw(2);
      // this.showDraw(3);
      // this.showDraw(4);
      // this.showDraw(5);
    },
    clickEvent(obj) {
      this.$emit("DRAWEVENT", obj);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 100%;
}
</style>
