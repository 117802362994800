<template>
  <div class="map-row">
    <div class="map-l">
      <div class="search">
        <el-input v-model="keyname" placeholder="" clearable /><el-button
          :icon="Search"
          type="primary"
          round
          @click="btn_search"
          >{{ $t("map.track_cx") }}</el-button
        ><el-button :icon="Delete" type="danger" round @click="btn_delete">{{
          $t("map.shanchu")
        }}</el-button>
      </div>
      <el-table
        :data="tableData"
        style="height: 90%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column prop="rRectType" :label="t('map.rRectType')">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span style="margin-left: 10px">{{
                get_rect_type(scope.row.rRectType)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rRectName" :label="t('map.rRectName')" />
        <el-table-column prop="rCompanyID_name" :label="t('map.rCompanyID')" />
      </el-table>
    </div>
    <div class="map-r">
      <GpsDraw ref="mainDraw" @DRAWEVENT="draw_event"></GpsDraw>
    </div>
    <GpsFormNew
      @saveEvent="saveEvent"
      @cancelEvent="cancelEvent"
      :formArray="formArray"
      :row="curr_row"
      :dialogTitle="t('map.rRectPoints')"
      ref="refAnyCom"
    ></GpsFormNew>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
// import { Delete, Search } from "@element-plus/icons-vue";
import GpsFormNew from "./components/GpsFormNew.vue";
import GpsDraw from "../../components/GpsDraw.vue";
import { useI18n } from "vue-i18n";
import {
  Convert_BD09_To_GCJ02,
  wgs84togcj02,
  gcj02towgs84,
} from "../../utils/gps";
import { ElNotification, ElMessageBox, ElMessage } from "element-plus";
import api from "@/api";
import { ca } from "element-plus/es/locale";
import refresh_table from "../../utils/refrshTable";

const { t } = useI18n();

let formCols = [
  {
    type: "sel",
    label: t("map.rCompanyID"),
    field: "rCompanyID",
    placeholder: t("map.rCompanyID"),
    val: "",
    required: true,
  },
  {
    type: "ipt",
    label: t("map.rRectName"),
    field: "rRectName",
    placeholder: t("map.rRectName"),
    val: "",
    required: true,
  },
  {
    type: "ipt",
    label: t("map.rRectPoints"),
    field: "rRectPoints",
    placeholder: t("map.rRectPoints"),
    val: "",
    required: true,
  },
  {
    type: "ipt",
    label: t("map.rImg"),
    field: "rImg",
    placeholder: t("map.rImg"),
    val: "",
    required: false,
  },
  {
    type: "ipt",
    label: t("map.rMore"),
    field: "rMore",
    placeholder: t("map.rMore"),
    val: "",
    required: false,
  },
  // {
  //   type: "ipt",
  //   label: t("map.rSortCode"),
  //   field: "rSortCode",
  //   placeholder: t("map.rSortCode"),
  //   val: "100",
  //   required: true,
  // },
];
//表单操作
const formArray = reactive(formCols);
const curr_row = ref({});
const refAnyCom = ref(null);
const keyname = ref("");
const mainDraw = ref(null);

const tableData = ref([]);

var overlays = [];
const overlaycomplete = (e) => {
  // console.log(e.drawingMode);
  overlays.push(e.overlay);
  // console.log(e.overlay.getRadius());

  var row = {
    id: 0,
    rCompanyID: "",
    rRectName: "",
    rRectPoints: "",
    rRealRectPoints: "",
    rImg: "",
    rMore: "",
    rSortCode: "100",
    rRectType: 0,
  };
  if (e.drawingMode == "marker") {
    // data.type = 1;

    let gg = Convert_BD09_To_GCJ02(e.overlay.point.lng, e.overlay.point.lat);
    row.rRectType = 4;
    row.rRectPoints = gg.lng + "," + gg.lat;
  } else if (e.drawingMode == "circle") {
    // data.type = 2;

    let gg = Convert_BD09_To_GCJ02(e.overlay.point.lng, e.overlay.point.lat);
    row.rRectType = 2;
    row.rRectPoints = gg.lng + "," + gg.lat + "|" + e.overlay.getRadius();
  } else if (e.drawingMode == "polyline") {
    // data.type = 5;

    var data_param = "";
    var endI = e.overlay.getPath().length - 1;
    for (var i = 0; i < e.overlay.getPath().length; i++) {
      var gg = Convert_BD09_To_GCJ02(
        e.overlay.getPath()[i].lng,
        e.overlay.getPath()[i].lat
      );
      if (i != endI) {
        data_param += gg.lng + "," + gg.lat + ";";
      } else {
        data_param += gg.lng + "," + gg.lat;
      }
    }
    // data.param = data_param;
    row.rRectType = 3;
    row.rRectPoints = data_param;
  } else if (e.drawingMode == "polygon") {
    // data.type = 4;

    var data_param = "";
    var endI = e.overlay.getPath().length - 1;
    for (var i = 0; i < e.overlay.getPath().length; i++) {
      var gg = Convert_BD09_To_GCJ02(
        e.overlay.getPath()[i].lng,
        e.overlay.getPath()[i].lat
      );
      if (i != endI) {
        data_param += gg.lng + "," + gg.lat + ";";
      } else {
        data_param += gg.lng + "," + gg.lat;
      }
    }
    row.rRectType = 1;
    row.rRectPoints = data_param;
  } else if (e.drawingMode == "rectangle") {
    // data.type = 3;
    var data_param = "";
    var endI = e.overlay.getPath().length - 1;
    for (var i = 0; i < e.overlay.getPath().length; i++) {
      var gg = Convert_BD09_To_GCJ02(
        e.overlay.getPath()[i].lng,
        e.overlay.getPath()[i].lat
      );
      if (i != endI) {
        data_param += gg.lng + "," + gg.lat + "|";
      } else {
        data_param += gg.lng + "," + gg.lat;
      }
    }
    row.rRectType = 0;
    row.rRectPoints = data_param;
  }
  // console.log(row);
  curr_row.value = row;
  // e.overlay.type = data.param;
  refAnyCom.value.dialogVisible = true;
};

const init_data = () => {
  //
  getList();
  api.item_get_company({}).then((res) => {
    let ay = res.data.data.map((it) => {
      it.name = it.cName;
      it.value = it.id;
      return it;
    });
    formArray[0].data = ay;
  });
};

const getList = () => {
  api.sys_rect_grid({ keyname: keyname.value }).then((res) => {
    // console.log(res.data);
    tableData.value = res.data.data;
  });
};

//////////////////////////地图操作/////////////////////////////////

onMounted(() => {
  init_data();
});
const saveEvent = (temp) => {
  // console.log(temp);
  api.sys_rect_save(temp).then((res) => {
    ElNotification({
      title: t("commKey.MSG_TITLE"),
      message: t("commKey.MSG_" + res.data.msg_type),
      duration: 2000,
    });
    if (res.data.msg_type == 1 || res.data.msg_type == 2) {
      refAnyCom.value.dialogVisible = false;
      getList();
    }
    refresh_table("gps_vehicles,gps_devices,gps_sims", 5);
  });
};

const get_rect_type = (tt) => {
  // console.log(t);
  let rt = "1";
  switch (parseInt(tt)) {
    case 0:
      rt = t("map.juxing");
      break;
    case 1:
      rt = t("map.duobianxing");
      break;
    case 2:
      rt = t("map.yuanxing");
      break;
    case 3:
      rt = t("map.xianlu");
      break;
    case 4:
      rt = t("map.xingqudian");
      break;
  }
  return rt;
}; //0:矩形 1：多边形 2：圆形 3：线路 4：兴趣点
const btn_search = () => {
  getList();
};
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  console.log(val);
  multipleSelection.value = val;
  mainDraw.value.showModel(val);
};

const btn_delete = () => {
  if (multipleSelection.value.length == 0) {
    ElMessage({
      type: "error",
      message: t("map.qingxuanzeshanchuxiang"),
    });
    return;
  }
  ElMessageBox.confirm("delete the data. Continue?", "Warning", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(() => {
      let ids = multipleSelection.value.map((it) => it.ID);
      api.sys_rect_delete({ ids: ids }).then((res) => {
        // console.log(res.data);
        getList();
      });
    })
    .catch(() => {
      // ElMessage({
      //   type: "info",
      //   message: "Delete canceled",
      // });
    });
};

const draw_event = (obj) => {
  console.log(obj);
  var row = {
    id: 0,
    rCompanyID: "",
    rRectName: "",
    rRectPoints: "",
    rRealRectPoints: "",
    rImg: "",
    rMore: "",
    rSortCode: "100",
    rRectType: 0,
  };

  let ay = [];
  let ay84 = [];
  let pt84;

  switch (obj.type) {
    case "MARKER":
      row.rRectType = 4;
      row.rRectPoints = obj.lng + "," + obj.lat;
      pt84 = gcj02towgs84(obj.lng, obj.lat);
      row.rRealRectPoints = pt84[0] + "," + pt84[1];

      break;
    case "CIRCLE":
      row.rRectType = 2;
      row.rRectPoints = obj.lng + "," + obj.lat + "|" + obj.radius;
      pt84 = gcj02towgs84(obj.lng, obj.lat);
      row.rRealRectPoints = pt84[0] + "," + pt84[1] + "|" + obj.radius;
      break;
    case "POLYGON":
      row.rRectType = 1;
      ay = obj.ay_lnglat.map((it) => {
        return it.lng + "," + it.lat;
      });
      ay84 = obj.ay_lnglat.map((it) => {
        pt84 = gcj02towgs84(it.lng, it.lat);
        return pt84[0] + "," + pt84[1];
      });
      row.rRectPoints = ay.join(";");
      row.rRealRectPoints = ay84.join(";");
      break;
    case "POLYLINE":
      row.rRectType = 3;
      ay = obj.ay_lnglat.map((it) => {
        return it.lng + "," + it.lat;
      });
      row.rRectPoints = ay.join(";");
      ay84 = obj.ay_lnglat.map((it) => {
        pt84 = gcj02towgs84(it.lng, it.lat);
        return pt84[0] + "," + pt84[1];
      });
      row.rRealRectPoints = ay84.join(";");
      break;
    case "RECTANGLE":
      row.rRectType = 0;
      ay = obj.ay_lnglat.map((it) => {
        return it.lng + "," + it.lat;
      });
      row.rRectPoints = ay.join(";");
      ay84 = obj.ay_lnglat.map((it) => {
        pt84 = gcj02towgs84(it.lng, it.lat);
        return pt84[0] + "," + pt84[1];
      });
      row.rRealRectPoints = ay84.join(";");
      break;
  }
  curr_row.value = row;
  refAnyCom.value.dialogVisible = true;
};

const cancelEvent = () => {
  mainDraw.value.clearDraw();
};
</script>

<style lang="scss" scoped>
.map-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .map-l {
    width: 350px;
    height: (100vh-7.1);
    .search {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 5px 0;
    }
  }
  .map-r {
    flex: 1;
    position: relative;
    #allmap {
      width: 100%;
      height: (100vh-8);
    }
    .clear {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}
.map-row {
  padding: 0px;
}
.map-l {
  background: #fafafa;
  border: 1px solid #ccc;
}
.search {
  padding: 10px !important;
  border-bottom: 1px solid #ccc;
}
::v-deep .search .is-round:first-child {
  background: #ccc !important;
}
::v-deep .el-table--fit {
  margin: 10px;
  width: 330px !important;
  border: 1px solid #eee;
}
</style>
